import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {isMobile} from 'react-device-detect';

const FounderPage = () => {

    return (
        <ImageList variant="masonry" cols={isMobile ? 2 : 4}>
            {itemData.map((item) => (
                <ImageListItem key={item.id}>
                    <img
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        title={item.title}
                        subtitle={item.author}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    )
}

export default FounderPage;


const itemData = [
    {
        id: 1,
        img: process.env.REACT_APP_BASE_URL + '/assets/image/founder/team-1.png',
        title: 'Putra Satria',
        author: 'Founder',
    },
    {
        id: 2,
        img: process.env.REACT_APP_BASE_URL + '/assets/image/founder/team-3.png',
        title: 'Arya Pradana',
        author: 'Founder',
    },
    {
        id: 3,
        img: process.env.REACT_APP_BASE_URL + '/assets/image/founder/team-2.png',
        title: 'Ahmad Zulpakar Alfiansyah',
        author: 'Founder',
    },
    {
        id: 4,
        img: process.env.REACT_APP_BASE_URL + '/assets/image/founder/team-4.png',
        title: 'Ade Novianto',
        author: 'Founder',
    },

];
