import * as React from 'react';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';
import {EventAvailableRounded, FlagCircleRounded, HomeRounded, InfoRounded, VerifiedUser} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const BottomNavigator = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(false);

    function handleClick(path: any) {
        navigate(path)
    }

    return (
        <AppBar position={'sticky'} className={'bottom-navigation'}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'yellow'
                }}
            >
                <BottomNavigationAction label="Home"
                                        icon={<HomeRounded/>}
                                        onClick={() => handleClick("/")}/>
                <BottomNavigationAction label="Event"
                                        icon={<EventAvailableRounded/>}
                                        onClick={() => handleClick("/event")}/>
                <BottomNavigationAction label="Member"
                                        icon={<VerifiedUser/>}
                                        onClick={() => handleClick("/member")}/>
                <BottomNavigationAction label="Race"
                                        icon={<FlagCircleRounded/>}
                                        onClick={() => handleClick("/race")}/>
                <BottomNavigationAction label="About" icon={<InfoRounded/>}
                                        onClick={() => handleClick("/about")}/>
            </BottomNavigation>
        </AppBar>
    );
}

export default BottomNavigator;
