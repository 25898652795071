import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import React from "react";
import logo from '../../../../assets/image/Pushbike tangerang logo.png'
import "./navbar.css"
import {useNavigate} from "react-router-dom";

const ResponsiveAppBar = () => {
    const navigation = useNavigate();

    const handleProfileMenuOpen = () => {
        navigation('/login')
    };

    return (
        <>
            <AppBar position="sticky" className={'navbar'}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img src={logo} alt="logo" height={35}/>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                        </Typography>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'flex'}, justifyContent: 'end'}}>
                            <Button
                                sx={{my: 2, color: 'white', display: 'block'}}
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleProfileMenuOpen}
                            >
                                Login
                            </Button>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default ResponsiveAppBar;
