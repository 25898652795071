import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';
import {
    EventAvailableRounded,
    HomeRounded,
    MiscellaneousServicesRounded,
    SupervisedUserCircle
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import logo from "../../../assets/image/logo-pbt.png";

export const BottomNavigatorProtected = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(false);

    function handleClick(path: any) {
        navigate(path)
    }

    return (
        <AppBar position={'sticky'} className={'bottom-navigation'}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'yellow'
                }}
            >
                <BottomNavigationAction label="Home"
                                        icon={<HomeRounded/>}
                                        onClick={() => handleClick("/")}/>

                <BottomNavigationAction label="Event"
                                        icon={<EventAvailableRounded/>}
                                        onClick={() => handleClick("/event")}/>
                <BottomNavigationAction/>

                <div className={'bottom-navigation-icon'} onClick={() => handleClick("/member")}>
                    <img src={logo} alt=""/>
                </div>

                <BottomNavigationAction label="Riders"
                                        icon={<SupervisedUserCircle/>}
                                        onClick={() => handleClick("/riders")}/>

                <BottomNavigationAction label="Setting" icon={<MiscellaneousServicesRounded/>}
                                        onClick={() => handleClick("/setting")}/>
            </BottomNavigation>
        </AppBar>
    );
}
