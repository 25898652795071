import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import React, {useEffect} from "react";
import logo from '../../../assets/image/Pushbike tangerang logo.png';
import noImage from '../../../assets/image/no-image-icon-33.png';
import "./navbar.css"
import {useNavigate} from "react-router-dom";
import {fetchUsersAction} from '../../../service/store/action/usersAction';
import {useDispatch, useSelector} from 'react-redux';

export const ResponsiveAppBarProtected = ({session, userDetail}: any) => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    // const {session, userDetail} = props;
    const handleProfileMenuOpen = () => {
        navigation('/login')
    };

    const {isLoading, data} = useSelector(({usersState}) => usersState.users);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchUsersAction(userDetail.id, session.access_token));
    }, [dispatch, userDetail.id, session.access_token]);

    return (
        <>
            <AppBar position="sticky" className={'navbar'}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img src={logo} alt="logo" height={35}/>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                        </Typography>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'flex'}, justifyContent: 'end'}}>
                            {!isLoading ? (
                                <div className={'image-navbar'}>
                                    <span className={'badge'}>Online</span>
                                    {data.result.image !== 'default.png' ? (
                                        <img src={data.result.image} alt=""/>
                                    ) : (
                                        <img src={noImage} alt=""/>
                                    )}
                                </div>
                            ) : (
                                <Button
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={handleProfileMenuOpen}
                                >
                                    Login
                                </Button>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}
