import Container from "@mui/material/Container";
import React from "react";
import logo from "../../../../assets/image/logo-pbt-2.png";
import GalleryPage from "./GalleryPage";
import FounderPage from "./FounderPage";
// import { useNavigate } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";
import { warningAlert } from "../../../../service/helper/handlingError";


const HomePage = () => {

    // const navigate = useNavigate();

    function handleClick(path: any) {
        warningAlert('Mohon maaf registrasi telah ditutup! Kuota sudah penuh!!!')
        // navigate(path)
    }

    return (
        <Container>
            <div className={'home-page'}>
                <div className={'about'}>
                    <h1 className={'title'}>Pushbike Tangerang</h1>
                    <h3>"Dari Kita Untuk Kita"</h3>
                    <p>
                        Pushbike Tangerang merupakan komunitas sepeda anak roda dua tanpa pedal. Komunitas
                        in mencakup daerah Kota Tangerang, Kabupaten Tangerang, dan sekitarnya. Anggota
                        Pushbike Tangerang berjumlah ± 270 anak berusia antara 18 bulan hingga 7 tahun yang
                        terus bertambah hingga saat ini serta pengurus yang merupakan perwakilan orang tua
                        anggota. Semakin berkembangnya komunitas pushbike tangerang saat ini ditandai dengan
                        selalu mengakomodir kegiatan latihan, event race maupun kegiatan positif lainnya untuk
                        anak-anak dan orang tua member.
                    </p>
                    <h3>"Pushbike Tangerang Mantab Jasa"</h3>
                </div>
                <div className={'logo'}>
                    <img src={logo} alt="logo Pushbike Tangerang" style={{ height: '250px' }} />
                </div>
            </div>
            <div className="box-content">
                <Container>
                    <h1>Cara Bergabung Komunitas Kami</h1>
                    <h4 className={'mb-0'}>Persyaratan</h4>
                    <ul className={'text-bold-with-small'}>
                        <li>Tidak terdaftar di komunitas lain</li>
                        <li>Memiliki sepeda keseimbangan</li>
                        <li>Usia minimal adalah 1 tahun 6 bulan dan maksimal 7 tahun</li>
                        <li>Bersedia mengikuti persyaratan dan ketentuan dari Komunitas Pushbike Tangerang</li>
                    </ul>
                    <h4 className={'mb-0'}>Persiapan Data</h4>
                    <ul className={'text-bold-with-small'}>
                        <li>Data Rider</li>
                        <li>Foto Rider</li>
                        <li>Foto Akte/KIA</li>
                        <li>Data orang tua</li>
                    </ul>
                    <div className={'button-join'}>
                        <button className={'btn-join'}
                            onClick={() => handleClick('register')}>
                            Daftar Sekarang
                        </button>
                    </div>
                </Container>
            </div>
            <div className={'box-content'}>
                <Container>
                    <h1 style={{ color: 'black' }}>Gallery</h1>
                    <GalleryPage />
                </Container>
            </div>

            <div className={'box-content'}>
                <Container>
                    <h1 style={{ color: 'black' }}>Founder</h1>
                    <FounderPage />
                </Container>
            </div>
            <a href="https://wa.me/6281328646069?text=Hallo%20Admin," target={'_blank'} rel="noreferrer">
                <div className={'floating'}>
                    <WhatsApp />
                </div>
            </a>
        </Container>
    )
}

export default HomePage;
