import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import img1 from '../../../../assets/image/galery/IMG_5400.jpg';
import img2 from '../../../../assets/image/galery/IMG_5183.jpg';
import img3 from '../../../../assets/image/galery/IMG_5285.jpg';
import img4 from '../../../../assets/image/galery/IMG_5326.jpg';
import img5 from '../../../../assets/image/galery/IMG_5329.jpg';
import img6 from '../../../../assets/image/galery/IMG_5351.jpg';
import img7 from '../../../../assets/image/galery/IMG_5371.jpg';
import img8 from '../../../../assets/image/galery/IMG_5427.jpg';
import img9 from '../../../../assets/image/galery/IMG_5928.jpg';

const GalleryPage = () => {
    return (
        <div style={{paddingBottom: 10}}>
            <ImageList variant="masonry" cols={3} rowHeight={250}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                            style={{
                                borderRadius: 15
                            }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}
export default GalleryPage;
const itemData = [
    {
        img: img1,
        title: 'Breakfast',
    },
    {
        img: img2,
        title: 'Burger',
    },
    {
        img: img3,
        title: 'Camera',
    },
    {
        img: img4,
        title: 'Camera',
    },
    {
        img: img5,
        title: 'Camera',
    },
    {
        img: img6,
        title: 'Camera',
    },
    {
        img: img7,
        title: 'Camera',
    },
    {
        img: img8,
        title: 'Camera',
    },
    {
        img: img9,
        title: 'Camera',
    },
];
