import * as React from "react";
import Container from "@mui/material/Container";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import pageNotFound from '../../../../assets/image/page-not-found.webp'

const ErrorPage = () => {

    const navigate = useNavigate();
    function handleClick(path: any) {
        navigate(path);
    }

    return (
        <>
            <div className={'error-page'}>
                <Container>
                    <div>
                        <img src={pageNotFound} alt="page not found" width={'60%'}/>
                    </div>
                    <div>
                        <Button variant={'contained'}
                                onClick={() => handleClick("/")}>
                            Back To Home
                        </Button>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ErrorPage;
